import { apiGet, apiPost } from './index'

export async function listMain(data) {
	return await apiPost("/api/BillRecord/mainlist", data);
}

export async function listBillRecord(data) {
	return await apiPost("/api/BillRecord/list", data);
}

export async function fullListBillRecord(data) {
	return await apiPost("/api/BillRecord/fulllist", data);
}

export function getBillRecord(data) {
	let url = "/api/BillRecord/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postBillRecord(data) {
	return apiPost("/api/BillRecord/edit", data);
}

export function addBillRecord(data) {
	return apiPost("/api/BillRecord/add", data);
}

export function deleteBillRecord(data) {
	return apiGet("/api/BillRecord/delete/" + data);
}
