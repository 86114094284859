<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/BillRecord/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="yymm" class="col-sm-2 col-form-label">電費計算(月份)</label>
							<div class="col-sm-10">
								<input v-model="model.yymm"
									type="text"
									class="form-control"
									id="yymm"
									name="yymm"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="total" class="col-sm-2 col-form-label">發電總度數</label>
							<div class="col-sm-10">
								<input v-model="model.total"
									type="text"
									class="form-control"
									id="total"
									name="total"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="rate" class="col-sm-2 col-form-label">費率</label>
							<div class="col-sm-10">
								<input v-model="model.rate"
									type="text"
									class="form-control"
									id="rate"
									name="rate"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="rebate_money" class="col-sm-2 col-form-label">回饋金(％)</label>
							<div class="col-sm-10">
								<input v-model="model.rebate_money"
									type="text"
									class="form-control"
									id="rebate_money"
									name="rebate_money"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="beginDate" class="col-sm-2 col-form-label">計費起始日</label>
							<div class="col-sm-10">
								<input v-model="model.beginDate"
									type="text"
									class="form-control"
									id="beginDate"
									name="beginDate"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="endDatte" class="col-sm-2 col-form-label">件廢終止日</label>
							<div class="col-sm-10">
								<input v-model="model.endDatte"
									type="text"
									class="form-control"
									id="endDatte"
									name="endDatte"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="result" class="col-sm-2 col-form-label">本月電費收入</label>
							<div class="col-sm-10">
								<input v-model="model.result"
									type="text"
									class="form-control"
									id="result"
									name="result"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="freeback_way" class="col-sm-2 col-form-label">回饋金方式</label>
							<div class="col-sm-10">
								<input v-model="model.freeback_way"
									type="text"
									class="form-control"
									id="freeback_way"
									name="freeback_way"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="returnmy" class="col-sm-2 col-form-label">固定制回饋金</label>
							<div class="col-sm-10">
								<input v-model="model.returnmy"
									type="text"
									class="form-control"
									id="returnmy"
									name="returnmy"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="freeback_radio" class="col-sm-2 col-form-label">回饋金百分比</label>
							<div class="col-sm-10">
								<input v-model="model.freeback_radio"
									type="text"
									class="form-control"
									id="freeback_radio"
									name="freeback_radio"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getBillRecord, postBillRecord } from '@/api/BillRecord.js'

	export default {
		name: 'BillRecordEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					housecode: '',
					yymm: '',
					total: '',
					rate: '',
					rebate_money: '',
					beginDate: '',
					endDatte: '',
					result: '',
					freeback_way: '',
					returnmy: '',
					freeback_radio: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getBillRecord(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postBillRecord(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>